<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Dokter</b> {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn ==  true"
        @click="$router.push('/doctor/add')"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Table from '@/component/doctors/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      manipulateBtn: false,
      clinic_name: "Medhop"
    }
  },

  methods: {
    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {

        if (access_right[a] == "2002") {
          this.manipulateBtn = true
        }
      }
    },
  },

  mounted() {
    this.setActiveMenu()
    this.clinic_name = window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter" },
      { title: "Daftar Dokter" },
    ])
  },

}
</script>
